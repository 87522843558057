import Vue from 'vue'

Vue.use({
	install(Vue, options) {
		Vue.prototype.$redirectWithForm = function ({ url, method, data: formData }) {
			const form = document.createElement('form')
			form.action = url
			form.method = method
			form.style.display = 'none'
			Object.keys(formData).forEach((key) => {
				const input = document.createElement('input')
				input.name = key
				input.value = formData[key]
				form.appendChild(input)
			})
			document.body.appendChild(form)
			form.submit()
			return
		}
	},
})
