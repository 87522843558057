<script>
import { get, sync } from 'vuex-pathify'

export default {
	lang: 'layout',
	name: 'MobileMenuDrawer',
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
	},
	methods: {
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="pa-4 pr-0 pl-0"
		v-model="menuMobileDrawer"
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		fixed
	>
		<v-list-item class="px-2 pb-3">
			<div class="pl-4">
				<router-link :to="{ name: 'home' }">
					<Media
						:alt="$srv('BusinessInfo.name')"
						class="shrink mr-sm-2 mr-10"
						:src="$srv('SiteLogo.logo')"
						width="200"
					/>
				</router-link>
			</div>
			<v-spacer></v-spacer>
			<v-btn :ripple="false" plain text @click="menuMobileDrawer = false">
				<v-icon x-large>mdi-close-circle</v-icon>
			</v-btn>
		</v-list-item>
		<v-divider class="mb-4"></v-divider>
		<div>
			<v-list-item
				v-if="user"
				:to="{ name: 'user.account' }"
				@click="menuMobileDrawer = false"
				class="text-capitalize text-h6 pt-3 pb-3"
			>
				<v-icon class="mr-1"> mdi-account </v-icon>
				<span>{{ user.firstname }}</span>
			</v-list-item>
			<v-list-item v-else @click.stop="showLoginDrawer" class="text-capitalize text-h6 pt-3 pb-3">
				<v-icon> mdi-account </v-icon>
				<span>{{ 'acceso' | lang }}</span>
			</v-list-item>
			<v-list-item
				v-for="(linkData, i) of $srv('SiteNavbarLinks')"
				:key="i"
				:to="linkData.link"
				@click="menuMobileDrawer = false"
				class="text-capitalize text-h6 pt-3 pb-3"
			>
				{{ linkData.text }}
			</v-list-item>
		</div>
	</v-navigation-drawer>
</template>
