<script>
import fiscalImage from './asset_fiscal.png'

export default {
	lang: 'layout',
	name: 'Footer',
	data() {
		return {
			categorys: [
				{ name: 'Home', to: { name: 'home' } },
				{ name: 'Tienda', to: { name: 'shop', params: { filters: ['shop'] } } },
				{ name: '¿Quienes Somos?', to: { name: 'us' } },
				{ name: 'Como Comprar', to: { name: 'howto' } },
				{ name: 'Politicas de Privacidad', to: { name: 'privacy' } },
				{ name: 'Términos y condiciones', to: { name: 'terms' } },
				{ name: 'Envíos y devoluciones', to: { name: 'deliveryInfo' } },
				{ name: 'Medios de pago', to: { name: 'paymentsInfo' } },
			],
			fiscalImage,
			logo: this.$srv('SiteLogo.logoWhite'),
			businessInfo: this.$srv('BusinessInfo', {}),
		}
	},
}
</script>

<template>
	<v-footer class="black white--text text-center pt-14">
		<Container>
			<v-row>
				<v-col cols="12" md="4" class="d-flex align-center flex-column d-md-block">
					<Media :src="logo" max-width="150" contain class="mb-3 mb-sm-6" />
					<SafeLink v-if="businessInfo.afipLink" :to="businessInfo.afipLink">
						<Media :src="fiscalImage" max-width="60" contain />
					</SafeLink>
				</v-col>
				<v-col cols="12" md="4" class="text-center text-md-left">
					<div class="pb-8 font-weight-bold">{{ 'TIENDA' | lang }}</div>
					<div v-for="category in categorys" :key="category.name" class="pa-1 pl-0 text-subtitle-2">
						<router-link class="text-decoration-none" :to="category.to">
							<div class="secondary--text">
								{{ category.name }}
							</div>
						</router-link>
					</div>
				</v-col>
				<v-col cols="12" md="4" class="text-center text-md-left">
					<div class="pb-8 font-weight-bold">{{ 'CONTACTANOS' | lang }}</div>

					<div class="link-item">
						<div v-if="businessInfo.name">{{ businessInfo.name }}</div>
						<div v-if="businessInfo.taxNumber" class="pb-1">CUIT {{ businessInfo.taxNumber }}</div>
					</div>
					<!-- <a v-if="businessInfo.whatsapp" class="link-item">
						<v-icon>mdi-whatsapp</v-icon> {{ businessInfo.whatsapp }}
					</a> -->
					<!-- <a v-if="businessInfo.phone" class="link-item" :href="`tel:${businessInfo.phone}`">
						<v-icon>mdi-phone</v-icon> {{ businessInfo.phone }}
					</a> -->
					<a v-if="businessInfo.email" class="link-item" :href="`mailto:${businessInfo.email}`">
						<v-icon>mdi-email-multiple-outline</v-icon> {{ businessInfo.email }}
					</a>
					<a
						v-if="businessInfo.facebook"
						class="link-item"
						:href="businessInfo.facebook"
						target="_blank"
					>
						<v-icon>mdi-facebook</v-icon> Facebook
					</a>
					<a
						v-if="businessInfo.instagram"
						class="link-item"
						:href="businessInfo.instagram"
						target="_blank"
					>
						<v-icon>mdi-instagram</v-icon> Instagram
					</a>
					<a class="link-item" href="https://selecciones.com.ar" target="_blank" rel="sponsored">
						<v-icon>mdi-link-variant</v-icon> Revista Selecciones
					</a>
					<a class="link-item" href="https://biennatural.com.ar/" target="_blank" rel="sponsored">
						<v-icon>mdi-link-variant</v-icon> Bien Natural
					</a>
				</v-col>
				<v-col cols="12" class="text-center text-lg-left pt-14 text-caption">
					{{ new Date().getFullYear() }} — Selecciones. {{ 'Todos los derechos reservados' | lang }}
				</v-col>
			</v-row>
		</Container>
	</v-footer>
</template>

<style scoped>
.link-item {
	padding: 6px 0;
	color: white;
}
a.link-item {
	display: block;
	text-decoration: none;
}
.link-item >>> .v-icon {
	margin-right: 4px;
	color: white;
}
</style>
