<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'layout',
	name: 'AppBar',
	props: {
		showMenu: Boolean,
	},
	data() {
		return {
			searchText: null,
		}
	},
	computed: {
		user: get('shop/user'),
		routeSearch: get('route@query.search'),
		extendedHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return '0'
				default:
					return '90'
			}
		},
		appBarHeight() {
			return this.$vuetify.breakpoint.xs ? 180 : 100
		},
		absoluteBtns() {
			return this.$vuetify.breakpoint.xs
		},
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.qty, 0)
		},
	},
	watch: {
		routeSearch(value) {
			this.searchText = value
		},
	},
	methods: {
		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},
		showMenuMobileDrawer() {
			this.$store.set('shop/menuMobileDrawer', true)
		},
		search() {
			this.$shop.search(this.searchText)
		},
	},
}
</script>

<template>
	<v-app-bar extended :extension-height="extendedHeight" height="auto" clipped-right color="white" flat>
		<v-btn @click.stop="showMenuMobileDrawer" class="d-xs-block ml-1 d-sm-none" absolute top left icon>
			<v-icon> mdi-menu </v-icon>
		</v-btn>
		<Container class="pa-0 pt-5 pb-5">
			<v-row>
				<v-col
					cols="12"
					class="d-flex justify-center justify-sm-space-between align-center flex-column flex-sm-row"
				>
					<router-link :to="{ name: 'home' }">
						<Media class="mr-30" :src="$srv('SiteLogo.logo')" />
					</router-link>
					<br class="d-sm-none" />
					<v-spacer class="d-none d-sm-block" />
					<v-form :class="`${absoluteBtns && 'w100'} d-block flex-grow-1`" @submit.prevent="search">
						<v-text-field
							:class="`${absoluteBtns && 'w100 rounded-0'} font-header w100`"
							type="search"
							autocomplete="off"
							:placeholder="$lang('Buscar productos...')"
							append-icon="mdi-magnify"
							outlined
							:rounded="!absoluteBtns"
							:tile="absoluteBtns"
							solo
							flat
							hide-details
							v-model="searchText"
							@click:append="search"
						/>
					</v-form>
					<v-spacer class="d-none d-sm-block" />
					<div class="d-flex align-center">
						<v-btn
							v-if="user"
							class="d-none d-sm-flex primary--text text-h6 font-weight-bold text-lowercase mr-7 mr-sm-0 mt-1 mt-sm-0"
							text
							:absolute="absoluteBtns"
							:top="absoluteBtns"
							:right="absoluteBtns"
							:to="{ name: 'user.account' }"
						>
							<v-icon class="mr-1"> mdi-account </v-icon>
							<span v-if="!absoluteBtns">{{ user.firstname }}</span>
						</v-btn>
						<v-btn
							v-else
							class="d-none d-sm-flex primary--text text-h6 font-weight-bold text-lowercase mr-7 mr-sm-0 mt-1 mt-sm-0"
							text
							:absolute="absoluteBtns"
							:top="absoluteBtns"
							:right="absoluteBtns"
							@click.stop="showLoginDrawer"
						>
							<span v-if="!absoluteBtns">{{ 'acceso' | lang }}</span>
							<v-icon v-else> mdi-account </v-icon>
						</v-btn>
						<v-btn
							@click.stop="showCartDrawer"
							:absolute="absoluteBtns"
							:top="absoluteBtns"
							:right="absoluteBtns"
							icon
						>
							<v-badge :content="orderItemsQty" :value="!!orderItemsQty" color="error" overlap>
								<v-icon>mdi-cart-variant</v-icon>
							</v-badge>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</Container>
		<template v-slot:extension>
			<div class="d-none d-sm-flex justify-center w100">
				<SafeLink
					class="mx-4 px-2 text-h6 text-capitalize menu-item"
					v-for="(linkData, i) of $srv('SiteNavbarLinks')"
					:key="i"
					:to="linkData.link"
				>
					{{ linkData.text }}
				</SafeLink>
			</div>
		</template>
	</v-app-bar>
</template>

<style scoped>
.menu-item {
	position: relative;
}

.menu-item::before {
	content: '';
	background-color: #aaa;
	position: absolute;
	width: 0;
	height: 2px;
	top: 100%;
	left: 0;
	opacity: 0;
	transition: all 0.5s;
}

.menu-item.router-link-active::before,
.menu-item:hover::before {
	width: 100%;
	opacity: 1;
}

.menu-item.router-link-active::before {
	height: 6px;
	background-color: #000;
}
</style>
