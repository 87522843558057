<script>
import { get } from 'vuex-pathify'

export default {
	computed: {
		...get('autoLazy/*'),
		lastLazyLoaded() {
			return this.lazyIndex > 0 && this.lazyIndex == this.lastLazyLoadedIndex
		},
	},
}
</script>

<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 200px"
		v-if="!$isBotAgent && !lastLazyLoaded"
	>
		<v-progress-circular indeterminate size="50" color="primary" />
	</div>
</template>
