<script>
import { get } from 'vuex-pathify'
export default {
	metaInfo() {
		const { siteName, separator, metaDescription } = this.$srv('SitePageTitle', {})
		return {
			titleTemplate: (title) => {
				if (title) {
					return siteName ? `${title} ${separator} ${siteName}` : title
				} else {
					return siteName || ''
				}
			},
			meta: [{ vmid: 'd', name: 'description', content: metaDescription || '' }],
		}
	},
	computed: {
		appComponent: get('site/appComponent'),
	},
}
</script>

<template>
	<component :is="appComponent" />
</template>
