/* AUTO-GENERATED FILE - DO NOT EDIT */

/* vue-src/site/@tienda-selecciones/components.js */
/* vue-src/site/cc-operator-decidir/components.js */
/* vue-src/site/cc-operator-first-data/components.js */
/* vue-src/site/components.js */
/* vue-src/site/shop-payment-cash/components.js */
/* vue-src/site/shop-payment-credit-card/components.js */
/* vue-src/site/shop-payment-mercadopago/components.js */
/* vue-src/site/shop-payment-paypal/components.js */
/* vue-src/site/shop-payment-transbank/components.js */
/* vue-src/site/shop/components.js */

import Vue from 'vue'

export const Home = require('./@tienda-selecciones/home/view_Home.vue').default
export const DeliveryInfo = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_DeliveryInfo.vue')
export const HowTo = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_HowTo.vue')
export const PaymentInfo = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_PaymentInfo.vue')
export const Privacy = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_Privacy.vue')
export const TermsCondition = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_TermsCondition.vue')
export const Us = () => import(/* webpackChunkName: "others" */ './@tienda-selecciones/others/view_Us.vue')
export const CreditCardPayment = () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/view_CreditCardPayment.vue')
export const Checkout = () => import(/* webpackChunkName: "checkout" */ './shop/checkout/view_Checkout.vue')
export const ShopList = () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/view_ShopList.vue')
export const Product = () => import(/* webpackChunkName: "product" */ './shop/catalog/product/view_Product.vue')
export const Upsells = () => import(/* webpackChunkName: "product" */ './shop/catalog/upsell/view_Upsells.vue')
export const UserAccount = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserAccount.vue')
export const UserFavorites = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserFavorites.vue')
export const UserOrderDetail = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserOrderDetail.vue')
export const UserOrders = () => import(/* webpackChunkName: "account" */ './shop/account/view_UserOrders.vue')

const c = []
const r = (a,b) => {
	c.push(a)
	Vue.component(a,b)
}
export const componentExists = (name) => c.includes(name)
export const getComponentsNames = () => [...c]
export const filterComponents = (re) => c.filter(name => name.match(re))
r('AppBar', require('./@tienda-selecciones/layout/comp_AppBar.vue').default)
r('Footer', require('./@tienda-selecciones/layout/comp_Footer.vue').default)
r('MobileMenuDrawer', require('./@tienda-selecciones/layout/comp_MobileMenuDrawer.vue').default)
r('CollectionCard', require('./@tienda-selecciones/home/comp_CollectionCard.vue').default)
r('CollectionCardSection', require('./@tienda-selecciones/home/comp_CollectionCardSection.vue').default)
r('HomeProductCarouselSection', require('./@tienda-selecciones/home/comp_HomeProductCarouselSection.vue').default)
r('ImageCard', require('./@tienda-selecciones/home/comp_ImageCard.vue').default)
r('ImageCardSection', require('./@tienda-selecciones/home/comp_ImageCardSection.vue').default)
r('MainCarousel', require('./@tienda-selecciones/home/comp_MainCarousel.vue').default)
r('NewsLetterForm', require('./@tienda-selecciones/home/comp_NewsLetterForm.vue').default)
r('NewsletterSection', require('./@tienda-selecciones/home/comp_NewsletterSection.vue').default)
r('CreditCard-OperatorResolver-Decidir', () => import(/* webpackChunkName: "creditCard" */ './cc-operator-decidir/comp_CreditCard-OperatorResolver-Decidir.vue'))
r('CreditCard-OperatorResolver-FirstData', () => import(/* webpackChunkName: "creditCard" */ './cc-operator-first-data/comp_CreditCard-OperatorResolver-FirstData.vue'))
r('Autocomplete', require('./site/common/comp_Autocomplete.vue').default)
r('Button', require('./site/common/comp_Button.vue').default)
r('Container', require('./site/common/comp_Container.vue').default)
r('DatePickerDialog', require('./site/common/comp_DatePickerDialog.vue').default)
r('MagicButton', require('./site/common/comp_MagicButton.vue').default)
r('SafeLink', require('./site/common/comp_SafeLink.vue').default)
r('Select', require('./site/common/comp_Select.vue').default)
r('Textarea', require('./site/common/comp_Textarea.vue').default)
r('TextField', require('./site/common/comp_TextField.vue').default)
r('ValidationMessage', require('./site/common/comp_ValidationMessage.vue').default)
r('Validator', require('./site/common/comp_Validator.vue').default)
r('Media', require('./../__shared/media-comps/comp_Media.vue').default)
r('RatioBox', require('./../__shared/media-comps/comp_RatioBox.vue').default)
r('RatioImage', require('./../__shared/media-comps/comp_RatioImage.vue').default)
r('Cash-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_Cash-Payment-CheckoutConfirmCardContent.vue'))
r('Cash-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_Cash-Payment-CheckoutOptions.vue'))
r('CashCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-cash/comp_CashCardContent.vue'))
r('CreditCard-CheckoutConfirmButton', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-CheckoutConfirmButton.vue'))
r('CreditCard-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-CheckoutConfirmCardContent.vue'))
r('CreditCard-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-CheckoutOptions.vue'))
r('CreditCard-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCard-Payment-InstructionsCardContent.vue'))
r('CreditCardApp', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardApp.vue'))
r('CreditCardCheckoutOptionContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardCheckoutOptionContent.vue'))
r('CreditCardNumberForm', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardNumberForm.vue'))
r('CreditCardPlanBox', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardPlanBox.vue'))
r('CreditCardPlanSelector', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardPlanSelector.vue'))
r('CreditCardTransactionDetail', () => import(/* webpackChunkName: "checkout" */ './shop-payment-credit-card/comp_CreditCardTransactionDetail.vue'))
r('MercadoPago-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-CheckoutConfirmCardContent.vue'))
r('MercadoPago-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-CheckoutOptions.vue'))
r('MercadoPago-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPago-Payment-InstructionsCardContent.vue'))
r('MercadoPagoCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-mercadopago/comp_MercadoPagoCardContent.vue'))
r('Paypal-CheckoutConfirmButton', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-CheckoutConfirmButton.vue'))
r('Paypal-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-Payment-CheckoutConfirmCardContent.vue'))
r('Paypal-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_Paypal-Payment-CheckoutOptions.vue'))
r('PaypalCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-paypal/comp_PaypalCardContent.vue'))
r('Transbank-Payment-CheckoutConfirmCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-CheckoutConfirmCardContent.vue'))
r('Transbank-Payment-CheckoutOptions', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-CheckoutOptions.vue'))
r('Transbank-Payment-InstructionsCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_Transbank-Payment-InstructionsCardContent.vue'))
r('TransbankCardContent', () => import(/* webpackChunkName: "checkout" */ './shop-payment-transbank/comp_TransbankCardContent.vue'))
r('App', require('./shop/comp_App.vue').default)
r('AutoLazy', require('./shop/common/comp_AutoLazy.vue').default)
r('AutoLazyLoader', require('./shop/common/comp_AutoLazyLoader.vue').default)
r('CardLayout', require('./shop/common/comp_CardLayout.vue').default)
r('Carousel', require('./shop/common/comp_Carousel.vue').default)
r('CountryPhoneCodeSelector', require('./shop/common/comp_CountryPhoneCodeSelector.vue').default)
r('DiscountText', require('./shop/common/comp_DiscountText.vue').default)
r('PriceText', require('./shop/common/comp_PriceText.vue').default)
r('QtyInput', require('./shop/common/comp_QtyInput.vue').default)
r('FacebookAuthButton', require('./shop/login/comp_FacebookAuthButton.vue').default)
r('ForgotForm', require('./shop/login/comp_ForgotForm.vue').default)
r('GoogleAuthButton', require('./shop/login/comp_GoogleAuthButton.vue').default)
r('LoginDrawer', require('./shop/login/comp_LoginDrawer.vue').default)
r('LoginForm', require('./shop/login/comp_LoginForm.vue').default)
r('LoginForms', require('./shop/login/comp_LoginForms.vue').default)
r('SignupForm', require('./shop/login/comp_SignupForm.vue').default)
r('ThirdPartyLoginsButtons', require('./shop/login/comp_ThirdPartyLoginsButtons.vue').default)
r('CartDrawer', require('./shop/cart/comp_CartDrawer.vue').default)
r('CartItem', require('./shop/cart/comp_CartItem.vue').default)
r('PriceLayout', require('./shop/catalog/common/comp_PriceLayout.vue').default)
r('ProductDiscount', require('./shop/catalog/common/comp_ProductDiscount.vue').default)
r('ProductPrevPrice', require('./shop/catalog/common/comp_ProductPrevPrice.vue').default)
r('ProductPrice', require('./shop/catalog/common/comp_ProductPrice.vue').default)
r('ProductsCarousel', require('./shop/catalog/common/comp_ProductsCarousel.vue').default)
r('ProductsLayout', require('./shop/catalog/common/comp_ProductsLayout.vue').default)
r('ProductWishlistToggler', require('./shop/catalog/common/comp_ProductWishlistToggler.vue').default)
r('SkuChip', require('./shop/catalog/common/comp_SkuChip.vue').default)
r('ProductCard', require('./shop/catalog/product-card/comp_ProductCard.vue').default)
r('ProductCardImage', require('./shop/catalog/product-card/comp_ProductCardImage.vue').default)
r('OrderSummary-Item', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary-Item.vue'))
r('OrderSummary-Totals', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary-Totals.vue'))
r('OrderSummary', () => import(/* webpackChunkName: "order" */ './shop/order/comp_OrderSummary.vue'))
r('PaymentInstructions', () => import(/* webpackChunkName: "order" */ './shop/order/comp_PaymentInstructions.vue'))
r('CheckoutApp', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/comp_CheckoutApp.vue'))
r('AddressDialog', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_AddressDialog.vue'))
r('CheckoutCollapsedSummary', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutCollapsedSummary.vue'))
r('CheckoutPaymentCardContent', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutPaymentCardContent.vue'))
r('CheckoutStepLayout', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CheckoutStepLayout.vue'))
r('CountrySelector', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_CountrySelector.vue'))
r('DeliveryOptionCardContent-PickupPoint', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent-PickupPoint.vue'))
r('DeliveryOptionCardContent-Shipping', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent-Shipping.vue'))
r('DeliveryOptionCardContent', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/common/comp_DeliveryOptionCardContent.vue'))
r('CheckoutStep-confirm', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-confirm.vue'))
r('CheckoutStep-contact', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-contact.vue'))
r('CheckoutStep-delivery', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-delivery.vue'))
r('CheckoutStep-discount', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-discount.vue'))
r('CheckoutStep-init', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-init.vue'))
r('CheckoutStep-payment', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-payment.vue'))
r('CheckoutStep-signin', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/steps/comp_CheckoutStep-signin.vue'))
r('CheckoutConfirmCard-contact', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-contact.vue'))
r('CheckoutConfirmCard-delivery', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-delivery.vue'))
r('CheckoutConfirmCard-discount', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-discount.vue'))
r('CheckoutConfirmCard-payment', () => import(/* webpackChunkName: "checkout" */ './shop/checkout/confirm-cards/comp_CheckoutConfirmCard-payment.vue'))
r('DefaultProducts', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_DefaultProducts.vue'))
r('MobileFilterBtn', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_MobileFilterBtn.vue'))
r('MobileFiltersDrawer', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_MobileFiltersDrawer.vue'))
r('ShopFilters', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_ShopFilters.vue'))
r('ShopFiltersGroup', () => import(/* webpackChunkName: "shoplist" */ './shop/catalog/shop-list/comp_ShopFiltersGroup.vue'))
r('ProductActions', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductActions.vue'))
r('ProductImagesCarousel', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesCarousel.vue'))
r('ProductImagesDialog', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesDialog.vue'))
r('ProductImagesThumb', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductImagesThumb.vue'))
r('ProductStockAvailabilityMessage', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductStockAvailabilityMessage.vue'))
r('ProductStockQtyMessage', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_ProductStockQtyMessage.vue'))
r('TruncatedText', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_TruncatedText.vue'))
r('VariantsSelector', () => import(/* webpackChunkName: "product" */ './shop/catalog/product/comp_VariantsSelector.vue'))
r('UpsellProductCard', () => import(/* webpackChunkName: "product" */ './shop/catalog/upsell/comp_UpsellProductCard.vue'))
r('ChangePassword', () => import(/* webpackChunkName: "account" */ './shop/account/comp_ChangePassword.vue'))
r('ContactData', () => import(/* webpackChunkName: "account" */ './shop/account/comp_ContactData.vue'))
r('OrderDigitalItemsCard', () => import(/* webpackChunkName: "account" */ './shop/account/comp_OrderDigitalItemsCard.vue'))
r('OrderListCard', () => import(/* webpackChunkName: "account" */ './shop/account/comp_OrderListCard.vue'))
r('UserData', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserData.vue'))
r('UserpanelDrawer', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserpanelDrawer.vue'))
r('UserpanelLayout', () => import(/* webpackChunkName: "account" */ './shop/account/comp_UserpanelLayout.vue'))