import Vue from 'vue'
import { isPrerenderAgent, isBotAgent } from '@/__shared/utils/lib_uahelper'
//import router from '@/site/router'

Vue.prototype.$prerenderReady = function () {
	if (this.$isPrerenderAgent()) {
		this.$nextTick(() => {
			window.prerender_ready = true
		})
	}
}

Vue.prototype.$isPrerenderAgent = function () {
	return isPrerenderAgent
}

Vue.prototype.$isBotAgent = function () {
	return isBotAgent
}

//if(isPrerenderAgent)
//window.prerenderPushState = (url) => {
//	window.prerender_ready = false
//	router.push(url)
//}
