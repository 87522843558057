<script>
export default {
	props: {
		tile: {
			type: Boolean,
			default: true,
		},
		text: Boolean,
		icon: Boolean,
	},
	computed: {
		fontColorClass() {
			if (this.text || this.icon || !this.$attrs.color) return ''
			switch (this.$attrs.color) {
				default:
					return 'white--text'
			}
		},
	},
	inheritAttrs: false,
}
</script>
<template>
	<v-btn
		v-on="$listeners"
		:class="fontColorClass"
		:tile="icon ? false : tile"
		v-bind="{ ...$attrs, ...$props }"
	>
		<slot />
	</v-btn>
</template>
