<script>
export default {
	props: {
		title: String,
		products: Array,
		ctaTxt: String,
		link: String,
	},
}
</script>

<template>
	<div>
		<Container>
			<div class="text-center title pb-8">
				<h2 class="text-h5 font-weight-bold extra-letter-spacing">
					{{ title }}
				</h2>
			</div>
			<div v-if="ctaTxt && link" class="pb-8 d-flex justify-center">
				<SafeLink :to="link">
					<Button color="primary" x-large outlined>
						{{ ctaTxt }}
					</Button>
				</SafeLink>
			</div>
		</Container>
		<ProductsCarousel :products="products" />
	</div>
</template>

<style scoped>
.extra-letter-spacing {
	letter-spacing: 0.062em !important;
}
</style>
