import router from '@/site/router'
import store from '@/site/store'

router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.appComponent) {
		store.set('site/appComponent', to.meta.appComponent)
	} else {
		store.set('site/appComponent', store.get('site/defaultAppComponent'))
	}
	next()
})
