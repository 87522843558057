<script>
import { isBotAgent } from '@/__shared/utils/lib_uahelper'
import Srv from '@/__shared/utils/lib_srv'
const cdnBaseUrl = Srv('cdnBaseUrl')
const acceptWebp = Srv('acceptWebp')

const extensions = {
	video: ['mp4', 'cmv', 'mov'],
	image: ['jpg', 'jpeg', 'png'],
}

// const YoutubeVideo = () => import(/* webpackChunkName: "checkout" */ './youtube-video.vue')
const VImg = () => import('vuetify/lib/components/VImg')
export default {
	inheritAttrs: false,
	props: {
		src: [Object, String],
		imageProps: Object,
		videoProps: Object,
		youtubeVideoProps: Object,
		noAlt: Boolean,
	},
	components: {
		VImg,
		// YoutubeVideo,
	},
	computed: {
		cSrc() {
			if (typeof this.src == 'string') return this.src

			let url = this.src?.url
			if (!url) return null

			if (this.cType === 'image' && acceptWebp && !url.endsWith('.webp')) {
				url += '.webp'
			}

			return `${cdnBaseUrl}/${url}`
		},
		cType() {
			if (typeof this.src === 'string') {
				const ext = this.src.split('.').pop()
				if (extensions.video.includes(ext)) {
					return 'video'
				} else if (this.src.includes('youtube')) {
					return 'youtube-video'
				} else {
					return 'image'
				}
			} else {
				return this.src?.type || 'image'
			}
		},
		cProps() {
			const props = {}
			props.alt = this.noAlt ? '' : this.src?.alt || ''
			if (this.cType === 'image' || this.cType === 'gif') {
				props.as = 'RatioImage'
				props.srcKey = 'src'
				let width = this.src?.data?.width || undefined
				let height = this.src?.data?.height || undefined
				if (width && height) {
					props.original = { width, height }
				}
				Object.assign(props, this.$attrs)
				Object.assign(props, this.imageProps || {})
				if (isBotAgent) props.eager = true
			} else if (this.cType === 'video') {
				props.as = 'video'
				props.srcKey = 'src'
				Object.assign(props, this.$attrs)
				Object.assign(props, this.videoProps || {})
			} else if (this.cType === 'youtube-video') {
				props.as = 'YoutubeVideo'
				props.srcKey = 'key'
				Object.assign(props, this.$attrs)
				Object.assign(props, this.youtubeVideoProps || {})
			}
			props[props.srcKey] = this.cSrc
			delete props.srcKey
			return props
		},
		slotsKeys() {
			return Object.keys(this.$slots).filter((key) => key !== 'default')
		},
	},
}
</script>

<template>
	<component :is="cProps.as" v-bind="{ ...cProps, as: undefined }" v-on="$listeners">
		<template #default>
			<slot v-if="cType == 'image'"></slot>
			<slot v-else :name="cType"> </slot>
		</template>
		<template v-for="slotKey of slotsKeys" #[slotKey]>
			<slot :name="slotKey" />
		</template>
	</component>
</template>
