<script>
export default {
	data() {
		return {
			big: this.$srv('HomeCardBig'),
			small1: this.$srv('HomeCardSmall1'),
			small2: this.$srv('HomeCardSmall2'),
		}
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, md: false }" v-if="big && small1 && small2">
		<v-row :no-gutters="$vuetify.breakpoint.smAndDown">
			<v-col cols="12" sm="4" md="6">
				<CollectionCard
					:image="big.img"
					:title="big.title"
					:content="big.description"
					:cta-txt="big.ctaText"
					:cta-link="big.ctaLink"
				/>
			</v-col>
			<v-col cols="12" sm="4" md="3">
				<CollectionCard
					:image="small1.img"
					:title="small1.title"
					:cta-txt="small1.ctaText"
					:cta-link="small1.ctaLink"
					:content="small1.description"
					small
				/>
			</v-col>
			<v-col cols="12" sm="4" md="3">
				<CollectionCard
					:image="small2.img"
					:title="small2.title"
					:cta-txt="small2.ctaText"
					:cta-link="small2.ctaLink"
					:content="small2.description"
					small
				/>
			</v-col>
		</v-row>
	</Container>
</template>
