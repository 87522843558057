import Srv from '@/__shared/utils/lib_srv'

function _applyReplacements(str, replacements) {
	if (!replacements) return str

	if (typeof replacements == 'string' || typeof replacements == 'number') {
		replacements = { 1: replacements }
	} else if (Array.isArray(replacements)) {
		replacements = replacements.reduce((obj, value, i) => Object.assign(obj, { [i + 1]: value }), {})
	}
	for (let k in replacements) {
		// eslint-disable-next-line no-useless-escape
		str = str.replace(new RegExp(`\{${k}\}`, 'g'), replacements[k])
	}
	return str
}

function cleanText(str) {
	return str.replace(/\\n/g, '\n')
}

const _translations = Srv('lang.translations', {})
const _cachedTranslations = {}

export const langIso = Srv('lang.iso')
export const areTranslationsLoaded = (cacheKey) => {
	return !!_cachedTranslations[cacheKey]
}

export const addTranslations = (translations, cacheKey) => {
	if (cacheKey) {
		if (_cachedTranslations[cacheKey]) return
		_cachedTranslations[cacheKey] = true
	}
	for (let x in translations) {
		_translations[x] = (_translations[x] || []).concat(translations[x])
	}
}

export const translate = (namespace, value, replacements) => {
	let item = _translations[namespace] ? _translations[namespace].find(([literal]) => literal == value) : null
	let str = (item && item[1]) || value || ''
	return _applyReplacements(str, replacements)
}

export const translateText = (namespace, value, replacements) => {
	return translate(namespace, cleanText(value), replacements)
}

export const $lang = translate
export const $langText = translateText

export const LangPlugin = {
	install(Vue, options) {
		const DEFAULT_NS_SCOPE = options.defaultNamespaceScope
		const full = (namespace) =>
			namespace.match(/^[a-z]+\|/) ? namespace : `${DEFAULT_NS_SCOPE}|${namespace}`

		Vue.mixin({
			beforeCreate() {
				if (!this.$options.lang) return
				let namespace = full(this.$options.lang)
				if (!namespace) return

				this.$options.filters.lang = (value, ...args) => {
					if (typeof args[0] == 'string') {
						return translate(full(args[0]), value, args[1])
					} else {
						return translate(namespace, value, args[0])
					}
				}

				this.$lang = function (...args) {
					switch (args.length) {
						case 1:
							return translate(namespace, args[0], null)
						case 2:
							if (typeof args[1] == 'string') return translate(full(args[0]), args[1], null)
							else return translate(namespace, args[0], args[1])
						case 3:
							return translate(full(args[0]), args[1], args[2])
						default:
							return ''
					}
				}
				this.$langText = function (...args) {
					switch (args.length) {
						case 1:
							return translateText(namespace, args[0], null)
						case 2:
							if (typeof args[1] == 'string') return translateText(full(args[0]), args[1], null)
							else return translateText(namespace, args[0], args[1])
						case 3:
							return translateText(full(args[0]), args[1], args[2])
						default:
							return ''
					}
				}
			},
		})
	},
}
