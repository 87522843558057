<script>
export default {
	pageView: true,
	data() {
		return {
			featured1: this.$srv('HomeFeatured1'),
			featured2: this.$srv('HomeFeatured2'),
			catsFeatured: this.$srv('HomeCategoriesFeatured', []),
		}
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div>
		<MainCarousel class="mb-16" />
		<HomeProductCarouselSection
			v-if="featured1"
			:products="featured1.products"
			:title="featured1.title"
			:ctaTxt="featured1.ctaTxt"
			:link="featured1.link"
			class="mb-6"
		/>
		<CollectionCardSection class="mb-16" />
		<HomeProductCarouselSection
			v-if="featured2"
			:products="featured2.products"
			:title="featured2.title"
			:ctaTxt="featured2.ctaTxt"
			:link="featured2.link"
			class="mb-6"
		/>
		<NewsletterSection class="mb-16" />
		<ImageCardSection
			v-for="(catFeatured, i) of catsFeatured"
			:key="i"
			:image="catFeatured.img"
			:img-mobile="catFeatured.imgMobile"
			:cta-txt="catFeatured.ctaText"
			:cta-link="catFeatured.ctaLink"
			:text="catFeatured.title"
			:inverse="catFeatured.inverse"
			:products="catFeatured.products"
			class="mb-16"
		/>
	</div>
</template>
