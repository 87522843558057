<script>
export default {
	data() {
		return {
			items: this.$srv('HomeSliders', []),
		}
	},
	methods: {
		imgToShow(item) {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return item.imgMobile
				default:
					return item.img
			}
		},
		clickOut(item) {
			return item.link.split('')[0] == '/' ? true : false
		},
	},
}
</script>

<template>
	<Carousel
		:items="items"
		:options="{
			arrows: { md: true },
			arrowsInset: true,
			arrowsMargin: 10,
			autoplay: 3000,
		}"
		:flicking-options="{
			bounce: 0,
			circular: true,
		}"
	>
		<template #panel="{ item }">
			<SafeLink :to="item.link">
				<Media :src="imgToShow(item)" />
			</SafeLink>
		</template>
	</Carousel>
	<!-- <v-carousel hide-delimiters cycle light height="auto">
		<div v-for="(item, i) in items" :key="i">
			<SafeLink :to="item.link">
				<v-carousel-item :aspect-ratio="ratio" :src="imgToShow(item)" contain />
			</SafeLink>
		</div>
	</v-carousel> -->
</template>

<style scoped>
.top-index {
	position: relative;
	z-index: 2;
}
</style>
