<script>
export default {
	name: 'ProductCard',
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
	},
	methods: {
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
		},
	},
}
</script>

<template>
	<v-card hover flat tile @click="cardClick" :to="productRoute">
		<slot name="images" v-bind="{ mainImage, secondImage }">
			<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
		</slot>
		<div class="px-2 py-5 d-flex">
			<div class="flex-grow-1">
				<div class="line-clamp-3 font-1 pb-1">
					{{ product.name }}
				</div>
				<div class="pt-1">
					<PriceLayout
						:prevPrice="prevPrice"
						:price="price"
						:discountPct="discountPct"
						:currency="currency"
					/>
				</div>
			</div>
			<div class="pl-3">
				<ProductWishlistToggler :productId="product.id" />
			</div>
		</div>
	</v-card>
</template>
