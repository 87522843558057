<script>
export default {
	props: {
		image: String,
		imgMobile: String,
		text: String,
		ctaTxt: String,
		ctaLink: String,
	},
	computed: {
		small() {
			return this.$vuetify.breakpoint.smAndDown
		},
		cardHeight() {
			return this.small ? '' : 830
		},
		imgToShow() {
			return this.small ? this.imgMobile : this.image
		},
		ratio() {
			return this.small ? 2 : 0.6315
		},
	},
}
</script>

<template>
	<SafeLink :to="ctaLink">
		<Media
			:height="cardHeight"
			:src="imgToShow"
			:aspect-ratio="ratio"
			position="top center"
			:cover="!small"
		>
			<div class="h100 d-flex justify-center align-end text-center">
				<div class="seo-text">
					<h2>{{ text }}</h2>
				</div>
				<Button v-if="ctaTxt" elevation="0" color="primary" class="font-weight-bold mb-6 mb-md-12" large>
					{{ ctaTxt }}
				</Button>
			</div>
		</Media>
	</SafeLink>
</template>

<style scoped>
.seo-text {
	left: -8000px;
	width: 1px;
	height: 1px;
	position: relative;
	top: 0;
	overflow: hidden;
}
</style>
