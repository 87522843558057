<script>
import newsletterImage from './asset_newsletter-bg.png'
import newsletterMdImage from './asset_newsletter-md-bg.png'
import newsletterSmImage from './asset_newsletter-sm-bg.png'

export default {
	lang: 'home',
	data() {
		return {
			newsletterImage,
			newsletterSmImage,
			newsletterMdImage,
			mail: '',
		}
	},
	computed: {
		imgToShow() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return this.newsletterSmImage
				case 'sm':
					return this.newsletterMdImage
				default:
					return this.newsletterImage
			}
		},
	},
}
</script>

<template>
	<Container :fluid="{ sm: true }" class="elevation-4 pa-0">
		<v-parallax height="250" :src="imgToShow">
			<Container>
				<v-row class="text-center">
					<v-col cols="12" md="5" class="text-left black--text">
						<div class="text-h4 font-weight-bold">{{ 'NEWSLETTER' | lang }}</div>
						<br />
						<p>
							{{
								'Recibí nuestras ofertas especiales, consejos e información sobre tus productos favoritos.'
									| lang
							}}
						</p>
						<NewsLetterForm />
					</v-col>
				</v-row>
			</Container>
		</v-parallax>
	</Container>
</template>
