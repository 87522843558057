import Srv from '@/__shared/utils/lib_srv'
import { isBotAgent } from '@/__shared/utils/lib_uahelper'

let id = Srv('Analytics.fbpId')
if (id && !isBotAgent) {
	let loadScript = (f, b, e, v, n, t, s) => {
		if (f.fbq) return
		n = f.fbq = function () {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
		}
		if (!f._fbq) f._fbq = n
		n.push = n
		n.loaded = !0
		n.version = '2.0'
		n.queue = []
		t = b.createElement(e)
		t.async = !0
		t.src = v
		b.body.appendChild(t)
	}
	loadScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
	window.fbq('init', id)
}

export default (...args) => window.fbq?.(...args)
