<script>
export default {
	inheritAttrs: false,
	props: {
		src: String,
		alt: String,
		imgClass: [String, Object, Array],
		cover: Boolean,
		fill: Boolean,
		scaleDown: Boolean,
		backgroundColor: String,
		position: String,
		aspectRatio: [String, Number],
		original: Object,
		loader: Boolean,
	},
	data() {
		return { loading: false, error: false, iAspectRatio: null }
	},
	computed: {
		cStyle() {
			let objectFit = this.cover ? 'cover' : this.fill ? 'fill' : this.scaleDown ? 'scale-down' : 'contain'
			return {
				objectFit,
				objectPosition: this.position || 'center',
				display: this.error ? 'none' : null,
				backgroundColor: this.backgroundColor || null,
				opacity: this.loading ? 0 : 1,
			}
		},
		cAspectRatio() {
			if (this.aspectRatio) return Number(this.aspectRatio)
			if (this.original) return Number(this.original.width) / Number(this.original.height)
			return this.iAspectRatio || 0
		},
		cWidth() {
			return this.$attrs.width || this.original?.width || null
		},
	},
	watch: {
		src() {
			this.load()
		},
	},
	methods: {
		load() {
			if (this.src) {
				this.loading = true
				this.error = false
				let img = new Image()
				img.onload = () => {
					this.loading = false
					this.iAspectRatio = img.width / img.height
				}
				img.onerror = () => {
					this.error = true
					this.loading = false
				}
				img.src = this.src
			} else {
				this.error = true
				this.loading = false
			}
		},
	},
	created() {
		this.load()
	},
}
</script>

<template>
	<RatioBox
		v-on="$listeners"
		v-bind="$attrs"
		:width="cWidth"
		:aspect-ratio="cAspectRatio"
		:style="{ border: error ? '1px solid #ccc' : null }"
		class="ratio-image"
	>
		<img class="ratio-image__image" :alt="alt" :src="src" :class="imgClass" :style="cStyle" />
		<slot name="loader" v-if="loading && loader">
			<div class="w100 h100 d-flex justify-center align-center">
				<v-progress-linear color="primary" indeterminate style="width: 80%" />
			</div>
		</slot>
		<slot name="fallback" v-else-if="error">
			<div class="w100 h100 d-flex justify-center align-center">
				<v-icon>mdi-image-off</v-icon>
			</div>
		</slot>
		<div class="ratio-image__content">
			<slot />
		</div>
	</RatioBox>
</template>

<style lang="scss" scoped>
.ratio-image {
	&__image,
	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	&__image {
		z-index: 0;
	}
	&__content {
		z-index: 1;
	}
}
</style>
