<script>
export default {
	props: {
		validation: {
			type: Object,
		},
	},
	data() {
		return {
			comps: {},
			uncatched: {},
			ids: 0,
		}
	},
	provide() {
		return {
			registerValidatorComponent: (comp) => {
				if (typeof comp.setValidation == 'function' && typeof comp.getValidatorKey == 'function') {
					let id = (this.ids += 1)
					if (!comp.focus) {
						comp.focus = () => {
							comp.$el.setAttribute('tabindex', '-1')
							comp.$el.focus()
						}
					}
					this.comps[`comp_${id}`] = comp
					return id
				} else {
					return null
				}
			},
			unregisterValidatorComponent: (id) => {
				delete this.comps[`comp_${id}`]
			},
		}
	},
	watch: {
		validation: {
			handler(value) {
				let validation = { ...(value || {}) }
				for (let x in validation) {
					if (Array.isArray(validation[x])) {
						validation[x] = validation[x].join(' | ')
					}
				}
				this.validate(validation)
			},
			deep: true,
		},
	},
	methods: {
		validate(validation) {
			let firstError = false
			let foundKeys = []
			for (let comp of Object.values(this.comps)) {
				let key = comp.getValidatorKey()
				if (!key) continue
				let error = validation[key]
				if (error) foundKeys.push(key)
				comp.setValidation(error)
				if (!firstError && error) {
					firstError = true
					comp.focus()
					this.$emit('input-focused', comp)
				}
			}
			let uncatched = {}
			Object.keys(validation)
				.filter((k) => !foundKeys.includes(k))
				.forEach((k) => (uncatched[k] = this.validation[k]))
			this.uncatched = uncatched
		},
	},
	render() {
		if (typeof this.$scopedSlots.default == 'function') {
			return this.$scopedSlots.default(this.uncatched || {})
		} else {
			return this.$slots.default
		}
	},
}
</script>
