<script>
export default {
	lang: 'shop',
	name: 'CartItem',
	props: ['order', 'joinedItem', 'index'],
	data() {
		return {
			qty: this.joinedItem.totalQty,
			qtyInputLoading: false,
			deleteLoading: false,
		}
	},
	computed: {
		item() {
			return this.joinedItem.items[0]
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
		itemRoute() {
			if (this.item.refType == 'product') {
				let { urlName, productId: id, variantId: variant } = this.item.cartData
				let route = { name: 'product', params: { urlName, id } }
				if (variant) route.query = { variant }
				return route
			}
			return null
		},
		imageSize() {
			return this.isMobile ? 80 : 90
		},
		showQtyInput() {
			return this.item.type == 'physical'
		},
		currency() {
			return this.order.currency.sign
		},
	},
	watch: {
		qty() {
			this.updateItem()
		},
	},
	methods: {
		updateItem() {
			this.$shopApi.put({
				url: `/cart/item/${this.item.cartItemId}`,
				data: { qty: this.qty },
				loading: (v) => (this.qtyInputLoading = v),
				onSuccess: ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
		},
		deleteItem() {
			this.$shopApi.delete({
				url: `/cart/item/${this.item.cartItemId}`,
				loading: (v) => (this.deleteLoading = v),
				onSuccess: ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
		},
		hideCartDrawer() {
			this.$store.set('shop/cartDrawer', false)
		},
		gotoItem() {
			this.hideCartDrawer()
			this.$router.push(this.itemRoute)
		},
	},
}
</script>

<template>
	<div class="d-flex align-start flex-wrap flex-sm-nowrap">
		<div @click="gotoItem" class="mr-4" style="width: 20%">
			<Media
				:src="item.image"
				aspect-ratio="1"
				class="rounded d-block"
				style="border: 1px solid #ddd; cursor: pointer; height: auto; width: 100%"
				@click="gotoItem"
			/>
		</div>
		<div style="width: 75%">
			<div @click="gotoItem" style="cursor: pointer">
				{{ item.name }}
			</div>
			<portal-target :name="`CartItem.${index}.below-name`" />
			<div class="grey--text" @click="hideCartDrawer" v-if="item.descriptions">
				{{ item.descriptions.map((e) => e.text).join(' | ') }}
			</div>
			<portal-target :name="`CartItem.${index}.below-descriptions`" />
			<div class="mt-2 d-flex align-center">
				<div>
					<div
						v-for="(group, i) of joinedItem.finalPricesGroups"
						:key="i"
						class="d-flex align-center py-1"
					>
						<div class="grey--text font-1 pr-2" v-if="joinedItem.finalPricesGroups.length > 1">
							{{ group.qty }}x
						</div>
						<ProductPrice
							custom-class="font-2 black--text"
							:amount="group.finalPrice"
							:currency="currency"
						/>
						<ProductPrevPrice
							v-if="group.initPrice > group.finalPrice"
							:amount="group.initPrice"
							:currency="currency"
							class="pl-2 font-1"
						/>
					</div>
				</div>
				<div class="pl-4">
					<Button icon color="error" @click="deleteItem" :loading="deleteLoading">
						<v-icon>mdi-cart-remove</v-icon>
					</Button>
				</div>
			</div>
			<portal-target :name="`CartItem.${index}.below-price`" />
		</div>
		<v-spacer :class="{ w100: isMobile }" />
		<div
			class="d-flex align-center align-sm-end justify-start justify-sm-start flex-row flex-sm-column ml-0 ml-sm-4 mt-3 mt-sm-0"
			:class="{ w100: isMobile }"
		>
			<div
				class="d-flex flex-row flex-sm-column align-center justify-center align-sm-end py-sm-3"
				:class="{ w100: isMobile }"
			>
				<QtyInput
					v-if="showQtyInput"
					v-model="qty"
					:loading="qtyInputLoading"
					:unit-metric="item.unitMetric"
					:max-qty="item.cartData.maxQty"
					:pack-metric="item.packMetric"
					:step="item.metricFactor"
					class="mr-4 mb-sm-2 mr-sm-0"
				/>
				<v-spacer v-if="isMobile" />
				<div class="text-center px-4 py-1 rounded w100" :style="{ backgroundColor: '#f8f8f8' }">
					<span class="font-0 mr-2 grey--text" v-if="!isMobile">{{ 'Total' | lang }}</span>
					<PriceText
						:amount="joinedItem.finalTotal"
						:currency="currency"
						class="font-weight-bold font-3"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.qty-btn ::v-deep {
	.v-input__slot {
		background-color: white !important;
	}
	.qty-btn__minus,
	.qty-btn__plus {
		background-color: white;
	}
}
</style>
