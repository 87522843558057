<script>
export default {
	name: 'ProductCardImage',
	props: {
		mainImage: [Object, String],
		secondImage: [Object, String],
	},
	data() {
		return {
			firstHover: false,
		}
	},
}
</script>

<template>
	<v-hover v-slot="{ hover }">
		<div class="parent-container" @mouseover="firstHover = true">
			<Media :src="mainImage" width="100%" aspect-ratio="1" />
			<div class="child-container" v-if="secondImage && firstHover">
				<Media
					class="second-image"
					width="100%"
					:class="{ 'second-image--show': hover }"
					:src="secondImage"
				/>
			</div>
		</div>
	</v-hover>
</template>

<style scoped>
.parent-container {
	position: relative;
}
.child-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.second-image {
	transition: opacity 0.3s;
	opacity: 0;
}
.second-image--show {
	opacity: 1;
}
</style>
