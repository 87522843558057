import { Checkout } from '@/site/autoloader'

const meta = { appComponent: 'CheckoutApp', isCheckout: true }

export default [
	{
		path: '/checkout/init',
		name: 'checkout',
		component: Checkout,
		meta,
	},
	{
		path: '/checkout/:hash/:stepKey',
		name: 'checkout.step',
		component: Checkout,
		meta,
	},
]
