function getResultsLines(order, linesProps = {}) {
	let itemsLine = (() => {
		//let beforeValue = order.items.reduce((sum, item) => sum + item.initTotal, 0)
		let value = order.items.reduce((sum, item) => sum + item.initTotal, 0)
		return {
			//beforeValue,
			value,
			...(linesProps.items || {}),
		}
	})()

	let deliveryLine = (() => {
		return {
			text: order.delivery ? `${order.delivery.methodName} - ${order.delivery.optionName}` : null,
			value: order.delivery?.cost || 0,
			...(linesProps.delivery || {}),
		}
	})()

	let discountsLines = (() => {
		let lines = []
		let itemsBaseDiscountTotal = order.items.reduce((sum, item) => sum + item.discountTotal, 0)
		if (itemsBaseDiscountTotal) {
			let names = order.items.reduce((arr, item) => {
				for (let name of item.discountsNames || []) {
					if (!arr.includes(name.text)) arr.push(name.text)
				}
				return arr
			}, [])
			if (linesProps.itemsBaseDiscount?.name) {
				if (!names.includes(linesProps.itemsBaseDiscount.name)) {
					names.push(linesProps.itemsBaseDiscount.name)
				}
			}

			lines.push({
				text: names.join(' + '),
				value: -itemsBaseDiscountTotal,
				...(linesProps.discounts || {}),
				...(linesProps.itemsBaseDiscount || {}),
			})
		}
		for (let discount of order.discounts) {
			let value = -(discount.itemsDiscount + discount.deliveryDiscount)
			if (value) lines.push({ text: discount.discountName, value, ...(linesProps.discounts || {}) })
		}
		return lines
	})()

	let subtotalLine = (() => {
		return {
			value: itemsLine.value + deliveryLine.value,
			...(linesProps.subtotal || {}),
		}
	})()

	let totalLine = (() => {
		return {
			value: order.total,
			...(linesProps.total || {}),
		}
	})()

	return (() => {
		let lines = [itemsLine]
		if (deliveryLine.value) {
			lines.push(deliveryLine)
			if (discountsLines.length && linesProps.showSubtotal) {
				lines.push(subtotalLine)
			}
		}
		lines = lines.concat(discountsLines)
		lines.push(totalLine)
		return lines
	})()
}

function getMetricWord(metric, qty) {
	let spl = metric.split('/').map((s) => s.trim())
	if (qty == 1) return spl[0]
	else return `${spl[0]}${spl[1] || ''}`
}

function getJoinedItems(order) {
	for (let item of order.items) {
		item._key = `${item.refType}|${item.refId}|${item.customization?.id || ''}`
		item.unitMetricWord = getMetricWord(item.unitMetric, item.qty)
	}
	let jItems = order.items.reduce((arr, item) => {
		let key = item._key
		let jItem = arr.find((jItem) => jItem.key == key)
		if (!jItem) {
			jItem = { key, items: [] }
			arr.push(jItem)
		}
		jItem.items.push(item)
		return arr
	}, [])

	for (let jItem of jItems) {
		jItem.totalQty = jItem.items.reduce((sum, item) => sum + item.qty, 0)
		jItem.totalPackQty = jItem.items.reduce((sum, item) => sum + item.packQty, 0)
		jItem.finalTotal = jItem.items.reduce((sum, item) => sum + item.finalTotal, 0)
		jItem.finalDiscountTotal = jItem.items.reduce((sum, item) => sum + item.finalDiscountTotal, 0)
		jItem.unitMetricWord = getMetricWord(jItem.items[0].unitMetric, jItem.totalQty)
		jItem.packMetricWord = jItem.items[0].packMetric
			? getMetricWord(jItem.items[0].packMetric, jItem.totalPackQty)
			: null
		jItem.finalPricesGroups = jItem.items.reduce((groups, item) => {
			let group = groups.find((group) => group.finalPrice == item.finalPrice)
			if (!group) {
				groups.push({ finalPrice: item.finalPrice, initPrice: item.initPrice, qty: item.qty })
			} else {
				group.qty += item.qty
			}
			return groups
		}, [])
	}

	return jItems
}

function makeSummary(order) {
	return {
		get currency() {
			return order.currency.sign
		},
		getItems() {
			return order.items
		},
		getJoinedItems() {
			return getJoinedItems(order)
		},
		getResultsLines(resultsLinesProps) {
			return getResultsLines(order, resultsLinesProps)
		},
	}
}

module.exports = makeSummary
module.exports.default = makeSummary
