<script>
export default {
	name: 'QtyInput',
	props: {
		qtyModel: { type: Number, default: 1 },
		disabled: Boolean,
		maxQty: Number,
		loading: Boolean,
		unitMetric: String,
		packMetric: String,
		step: {
			type: Number,
			default: 1,
		},
	},
	model: {
		prop: 'qtyModel',
		event: 'updateQtyModel',
	},
	data() {
		return {
			qty: null,
			qtyInput: null,
		}
	},
	computed: {
		unitMetricWord() {
			if (!this.unitMetric) return ''
			return this.qty == 1 ? this.unitMetric.split('/')[0] : this.unitMetric.split('/').join('')
		},
		packMetricWord() {
			if (!this.packMetric) return ''
			return this.packQty == 1 ? this.packMetric.split('/')[0] : this.packMetric.split('/').join('')
		},
		cMaxQty() {
			return this.maxQty ? this.roundToStep(this.maxQty, false) : null
		},
		cStep() {
			return this.step || 1
		},
		packQty() {
			return Math.round(this.qty / this.cStep)
		},
	},
	watch: {
		qty(value) {
			this.$emit('updateQtyModel', value)
		},
		qtyModel(value) {
			this.setQty(value)
		},
	},
	methods: {
		roundToStep(n, up = false) {
			let div = Number((n / this.cStep).toFixed(2))
			let result = up ? Math.ceil(div) : Math.floor(div)
			return Number((this.cStep * result).toFixed(2))
		},
		addQty() {
			this.setQty(this.qty + this.cStep)
		},
		subtractQty() {
			this.setQty(this.qty - this.cStep)
		},
		setQty(n) {
			if (isNaN(n)) {
				n = this.qty
			}
			let newQty = this.limitQty(n)
			newQty = this.roundToStep(newQty, true)
			this.qty = newQty
			this.qtyInput = `${newQty} ${this.unitMetricWord}`
		},
		limitQty(n) {
			if (this.cMaxQty) {
				n = Math.min(this.cMaxQty, n)
			}
			return Math.max(this.cStep, n)
		},
		onFocus() {
			this.qtyInput = this.qty
		},
		onBlur() {
			this.setQty()
		},
	},
	created() {
		this.setQty(this.qtyModel)
	},
}
</script>

<template>
	<div class="d-flex qty-btn">
		<div class="d-flex align-center qty-btn__container">
			<Button
				@click="subtractQty"
				:disabled="loading || disabled"
				:ripple="false"
				class="d-inline rounded-0 qty-btn qty-btn__minus"
				text
				plain
				icon
			>
				<v-icon>mdi-minus</v-icon>
			</Button>
			<div class="qty-btn__tf-container" :class="{ 'qty-btn__tf-container--pack-metric': !!packMetric }">
				<v-text-field
					type="tel"
					v-model="qtyInput"
					@change="setQty"
					@focus="onFocus"
					@blur="onBlur"
					:loading="loading"
					:disabled="disabled"
					class="qty-btn__tf rounded-0"
					hide-details
					flat
					solo
					dense
					small
					:height="40"
				/>
				<span v-if="packMetric" class="qty-btn__tf-pack-metric-text grey--text"
					>{{ packQty }} {{ packMetricWord }}</span
				>
			</div>

			<Button
				@click="addQty"
				:ripple="false"
				:disabled="loading || disabled"
				class="d-inline rounded-0 qty-btn__plus"
				text
				plain
				icon
			>
				<v-icon>mdi-plus</v-icon>
			</Button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.qty-btn {
	&__container {
		border: 1px solid lightgrey;
	}
	&__tf-container {
		position: relative;
		&--pack-metric ::v-deep .v-text-field__slot {
			position: relative;
			top: -5px;
		}
	}
	&__tf {
		max-width: 120px !important;
		width: 120px !important;
		border-left: none !important;
	}
	&__tf ::v-deep .v-input__slot {
		background-color: transparent !important;
		input {
			text-align: center;
			font-size: 1rem;
			text-overflow: ellipsis;
		}
	}
	&__tf-pack-metric-text {
		position: absolute;
		bottom: 0;
		font-size: 12px;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
	}
}
</style>
