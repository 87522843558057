<script>
export default {
	props: {
		to: [Object, String],
		custom: [Boolean, String],
	},
	computed: {
		isAbsolute() {
			return typeof this.to == 'string' && this.to.match(/^[a-z]+:/i)
		},
		external() {
			return this.isAbsolute && !this.to.startsWith(window.location.origin)
		},
		link() {
			if (this.isAbsolute && !this.external) return this.to.substr(window.location.origin.length)
			return this.to
		},
		slotBind() {
			let { link, external } = this
			let to = external ? undefined : link
			let href = external ? link : undefined
			let target = href ? '_blank' : undefined
			let attrs = { to, href, target }
			return { link, external, attrs }
		},
		customComponent() {
			return this.custom === true ? 'div' : this.custom
		},
	},
}
</script>

<template>
	<component :is="customComponent" v-if="custom && to">
		<slot v-bind="slotBind" />
	</component>
	<a :href="link" v-bind="$attrs" target="_blank" v-else-if="external && to">
		<slot v-bind="slotBind" />
	</a>
	<router-link v-bind="$attrs" :to="link" v-else-if="to">
		<slot v-bind="slotBind" />
	</router-link>
	<div v-else>
		<slot v-bind="slotBind" />
	</div>
</template>
