import { make } from 'vuex-pathify'

const state = {
	defaultAppComponent: 'App',
	appComponent: 'App',
}

const mutations = make.mutations(state)

const actions = make.actions(state)

const getters = make.getters(state)

export default {
	name: 'site',
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
