const _all = []
let _id = 0

export const eventer = Object.freeze({
	listen(name, listener, opts) {
		let id = ++_id
		_all.push({ ...(opts || {}), listener, id, name })
		return id
	},
	once(name, listener, opts) {
		return eventer.listen(name, listener, { ...(opts || {}), once: true })
	},
	remove(id) {
		let idx = _all.findIndex((e) => e.id == id)
		if (idx >= 0) _all.splice(idx, 1)
	},
	removeBy(predicate) {
		_all.filter(predicate).forEach((e) => eventer.remove(e.id))
	},
	removeByEventName(name) {
		eventer.removeBy((e) => e.name == name)
	},
	async trigger(name, data) {
		let events = _all.filter((e) => e.name == name)
		for (let e of events) {
			await e.listener(data)
			if (e.once) eventer.remove(e.id)
		}
	},
})

let createComponentEventer = (component) => {
	let _ids = []
	return {
		...eventer,
		_ids: [],
		async trigger(name, data = {}) {
			data.component = component
			await eventer.trigger(name, data)
		},
		listen(...args) {
			let id = eventer.listen(...args)
			_ids.push(id)
			return id
		},
		once(...args) {
			let id = eventer.once(...args)
			_ids.push(id)
			return id
		},
		destroy() {
			_ids.forEach((id) => eventer.remove(id))
			_ids = []
		},
	}
}

export const EventerPlugin = {
	install(Vue) {
		Vue.mixin({
			beforeCreate() {
				this.$eventer = createComponentEventer(this)
				if (this.$options.eventer) {
					for (let x in this.$options.eventer) {
						this.$eventer.listen(x, this.$options.eventer[x].bind(this))
					}
				}
			},
			beforeDestroy() {
				this.$eventer.destroy()
			},
		})
	},
}
