<script>
export default {
	props: {
		ctaTxt: String,
		ctaLink: String,
		image: String,
		title: String,
		content: String,
		small: Boolean,
	},
}
</script>

<template>
	<SafeLink :to="ctaLink">
		<v-card class="w100 h100" color="#f1eff0" flat tile>
			<Media class="white--text align-end" width="100%" height="250px" cover :src="image" />
			<v-card-subtitle class="pa-4 pb-0">
				<h2 class="text-h5 font-weight-bold black--text t">{{ title }}</h2>
			</v-card-subtitle>

			<v-card-actions class="px-4 pt-2 pb-6">
				<v-row dense>
					<v-col cols="12" :md="small ? 12 : 9">
						{{ content }}
					</v-col>
					<v-col cols="12" md="3" :class="{ 'd-md-none': small }" class="text-left text-md-center">
						<Button elevation="0" color="primary" class="font-weight-bold">
							{{ ctaTxt }}
						</Button>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</SafeLink>
</template>
