var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Carousel',{attrs:{"items":_vm.items,"options":{
		arrows: { md: true },
		arrowsInset: true,
		arrowsMargin: 10,
		autoplay: 3000,
	},"flicking-options":{
		bounce: 0,
		circular: true,
	}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
	var item = ref.item;
return [_c('SafeLink',{attrs:{"to":item.link}},[_c('Media',{attrs:{"src":_vm.imgToShow(item)}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }