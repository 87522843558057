import { CreditCardPayment } from '@/site/autoloader'

export default [
	{
		path: '/credit-card-payment/:id',
		name: 'creditCardPayment',
		component: CreditCardPayment,
		meta: { appComponent: 'CreditCardApp' },
	},
]
