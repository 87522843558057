import Vue from 'vue'
import { eventer } from '@/__shared/utils/lib_eventer'
import { gtag, fbq } from './trackers'

eventer.listen('page:view', ({ component, title, location }) => {
	component?.$meta?.().refresh()

	location = location || window.location.href
	title = title || document.title

	if (!location.startsWith(window.location.origin)) {
		location = `${window.location.origin}${location}`
	}

	gtag('event', 'page_view', {
		page_title: title,
		page_location: location,
	})
	fbq('track', 'PageView')
})

Vue.mixin({
	mounted() {
		if (typeof this.$options.pageView == 'function') {
			let { title, location } = this.$options.pageView.call(this) || {}
			this.$eventer.trigger('page:view', { title, location })
		} else if (this.$options.pageView === true) {
			this.$eventer.trigger('page:view')
		}
	},
})
