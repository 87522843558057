import Srv from '@/__shared/utils/lib_srv'
import { isBotAgent } from '@/__shared/utils/lib_uahelper'
import isMobile from 'is-mobile'
// GOOGLE ANALYTICS
let id = Srv('Analytics.gaId')
let secondaryIds = Srv('Analytics.gaSecondaryIds') || []
if (id && !isBotAgent) {
	let s = document.createElement('script')
	s.async = true
	s.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
	document.body.appendChild(s)
	window.dataLayer = window.dataLayer || []
	window.gtag = function () {
		window.dataLayer.push(arguments)
	}
	window.gtag('js', new Date())
	window.gtag('set', {
		custom_map: {
			dimension1: 'device_type',
			dimension2: 'site_context',
		},
	})

	let configParams = {}
	configParams.send_page_view = false
	configParams.device_type = isMobile() ? 'mobile' : 'desktop'
	let contextName = Srv('contextName')
	if (contextName) configParams.site_context = contextName

	window.gtag('config', id, configParams)
	for (let secId of secondaryIds) {
		window.gtag('config', secId, configParams)
	}
}

export default (...args) => window.gtag?.(...args)
