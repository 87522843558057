<script>
import { Flicking } from '@egjs/vue-flicking'
import { breakpointValue } from '@/__shared/utils/lib_utils'

export default {
	name: 'Carousel',
	components: {
		Flicking,
	},
	props: {
		options: Object,
		flickingOptions: Object,
		items: Array,
		indexValue: Number,
		lazy: Boolean,
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	data() {
		return {
			moving: false,
			isMounted: false,
			currentIndex: this.indexValue || 0,
			initialized: false,
			autoplayItv: null,
			interacted: false,
		}
	},
	watch: {
		indexValue(value) {
			if (value !== this.currentIndex) {
				this.moveTo(value)
			}
		},
	},
	computed: {
		flicking() {
			return this.$refs.flicking
		},
		cFlickingOptions() {
			return {
				autoInit: true,
				threshold: 0,
				preventEventsBeforeInit: true,
				autoResize: true,
				moveType: ['strict', { count: this.moveBy }],
				...this.processOptions(this.flickingOptions || {}),
				defaultIndex: this.currentIndex,
				useFractionalSize: true,
			}
		},
		cOptions() {
			let defaults = {
				panelsPerView: 1,
				itemsPadding: 0,
				arrows: false,
				arrowsInset: false,
				arrowsMargin: 0,
				arrowsClass: '',
				viewportStyle: '',
				viewportClass: '',
				itemWrapperStyle: '',
				itemWrapperClass: '',
				activeWrapperClass: '',
				activeWrapperStyle: '',
				inactiveWrapperClass: '',
				inactiveWrapperStyle: '',
				duration: 500,
				moveBy: null,
				autoplay: 0,
			}
			return { ...defaults, ...this.processOptions(this.options || {}) }
		},
		middleIndex() {
			let visiblePanels = Math.floor(this.cOptions.panelsPerView)
			let middleIndex = Math.floor(visiblePanels / 2)
			if (visiblePanels % 2 == 0) middleIndex -= 1
			return middleIndex
		},
		minIndex() {
			if (this.cFlickingOptions.align == 'prev') {
				return 0
			} else {
				return this.middleIndex
			}
		},
		maxIndex() {
			if (this.cFlickingOptions.align == 'prev') {
				return this.items.length - Math.floor(this.cOptions.panelsPerView)
			} else {
				let visiblePanels = Math.floor(this.cOptions.panelsPerView)
				let middleIndex = Math.floor(visiblePanels / 2)
				if (visiblePanels % 2 == 0) middleIndex -= 1
				return this.items.length - middleIndex - 1
			}
		},
		canMovePrev() {
			if (this.cOptions.panelsPerView >= this.items.length) return false
			if (this.cFlickingOptions.circular) return true
			return this.currentIndex > this.minIndex
		},
		canMoveNext() {
			if (this.cOptions.panelsPerView >= this.items.length) return false
			if (this.cFlickingOptions.circular) return true
			return this.currentIndex < this.maxIndex
		},
		moveBy() {
			return this.cOptions.moveBy || Math.floor(this.cOptions.panelsPerView)
		},
		arrowsPosition() {
			let init = this.cOptions.arrowsInset ? 0 : 55
			return (init + this.cOptions.arrowsMargin) * (this.cOptions.arrowsInset ? 1 : -1)
		},
		parentClass() {
			let c = ['carousel-parent']
			if (!this.initialized) c.push('uninit')
			return c.join(' ')
		},
		viewportClass() {
			let c = [this.cOptions.viewportClass]
			let pad = Number(this.cOptions.itemsPadding)
			if (pad) c.push(`px-${pad}`)
			return c.join(' ')
		},
		viewportStyle() {
			return this.cOptions.viewportStyle
		},
		autoplayListeners() {
			if (!this.cOptions.autoplay) return {}
			return {
				mouseenter: () => this.pauseAutoplay(),
				mouseleave: () => this.playAutoplay(),
				touchstart: () => this.pauseAutoplay(),
				touchend: () => this.playAutoplay(),
			}
		},
	},
	methods: {
		processOptions(obj) {
			let opts = {}
			for (let [k, v] of Object.entries(obj)) {
				opts[k] = breakpointValue(this.$vuetify.breakpoint, v)
			}
			return opts
		},
		moveTo(index, duration, direction) {
			this.interacted = true
			if (this.moving) return
			if (this.cFlickingOptions.circular) {
				if (index < 0) index = this.items.length + index
				else if (index > this.items.length - 1) index -= this.items.length
			} else {
				index = Math.min(Math.max(index, this.minIndex), this.maxIndex)
			}
			duration = duration === undefined ? this.cOptions.duration : duration
			this.$refs.flicking.moveTo(index, duration, direction || null)
		},
		movePrev() {
			this.moveTo(this.currentIndex - this.moveBy, undefined, 'PREV')
		},
		moveNext() {
			this.moveTo(this.currentIndex + this.moveBy, undefined, 'NEXT')
		},
		onCarouselWillChange(event) {
			this.interacted = true
			this.currentIndex = event.index
			this.$emit('updateIndexValue', this.currentIndex)
		},
		getItemWrapperStyle(i) {
			let size = 100 / this.cOptions.panelsPerView
			let which = this.cFlickingOptions.horizontal === false ? 'height' : 'width'
			let s = [`${which}: ${size}%`, this.cOptions.itemWrapperStyle]
			if (this.currentIndex == i) s.push(this.cOptions.activeWrapperStyle)
			else s.push(this.cOptions.inactiveWrapperStyle)
			return s.join(';')
		},
		getItemWrapperClass(i) {
			let c = [this.cOptions.itemWrapperClass]
			let p = Number(this.cOptions.itemsPadding)
			let whichP = this.cFlickingOptions.horizontal === false ? 'py' : 'px'
			if (p) c.push(`${whichP}-${p}`)
			if (this.currentIndex == i) c.push(this.cOptions.activeWrapperClass)
			else c.push(this.cOptions.inactiveWrapperClass)
			return c.join(' ')
		},
		onMoveStart() {
			this.moving = true
			this.interacted = true
		},
		onMoveEnd() {
			this.moving = false
		},
		resize() {
			this.$refs.flicking.resize()
		},
		init(delay) {
			setTimeout(() => {
				if (!this.initialized && !this.cFlickingOptions.autoInit) {
					this.$refs.flicking.init()
				}
				this.$nextTick(() => {
					if (!this.$refs.flicking) return this.init()
					this.initialized = true
					this.resize()
					Object.defineProperty(this.$refs.flicking.control, 'activeIndex', {
						value: this.currentIndex,
					})
					this.initAutoplay()
				})
			}, delay || 0)
		},
		initAutoplay() {
			if (!this.cOptions.autoplay) return
			this.autoplayItv = setInterval(() => {
				if (this.canMoveNext) this.moveTo(this.currentIndex + this.moveBy, undefined, 'NEXT')
				else this.moveTo(0)
			}, this.cOptions.autoplay)
		},
		pauseAutoplay() {
			this.autoplayItv && clearInterval(this.autoplayItv)
		},
		playAutoplay() {
			this.pauseAutoplay()
			this.initAutoplay()
		},
		shouldRenderPanel(i) {
			if (!this.lazy) return true
			if (this.cFlickingOptions.circular && this.interacted) return true
			let leftIndex =
				this.cOptions.align == 'prev'
					? this.currentIndex - 1
					: Math.max(0, Math.floor(this.currentIndex - this.cOptions.panelsPerView / 2))
			return leftIndex <= i && i <= this.currentIndex + Math.ceil(this.cOptions.panelsPerView)
		},
	},
	created() {
		if (this.cFlickingOptions.autoInit) {
			this.init()
		}
	},
	beforeDestroy() {
		this.pauseAutoplay()
	},
}
</script>

<template>
	<div :class="parentClass" v-on="autoplayListeners">
		<Flicking
			ref="flicking"
			:style="viewportStyle"
			:class="viewportClass"
			@move-start="onMoveStart"
			@move-end="onMoveEnd"
			@will-change="onCarouselWillChange"
			:options="cFlickingOptions"
			@after-resize="$emit('after-resize')"
			@move="$emit('move')"
		>
			<div
				v-for="(item, i) of items"
				style="cursor: pointer"
				:key="i"
				:style="getItemWrapperStyle(i)"
				:class="getItemWrapperClass(i)"
				onmousedown="return false;"
			>
				<slot name="panel" v-bind="{ item, i, active: currentIndex == i }" v-if="shouldRenderPanel(i)" />
			</div>
		</Flicking>
		<slot
			name="left-arrow"
			v-if="cOptions.arrows"
			v-bind="{ canMovePrev, movePrev, arrowsPosition, cOptions }"
		>
			<Button
				v-if="canMovePrev"
				icon
				@click="movePrev"
				class="arrow arrow--left"
				:class="cOptions.arrowsClass"
				:style="{ left: `${arrowsPosition}px` }"
			>
				<v-icon class="arrow-icon">mdi-chevron-left</v-icon>
			</Button>
		</slot>
		<slot
			name="right-arrow"
			v-if="cOptions.arrows"
			v-bind="{ canMoveNext, moveNext, arrowsPosition, cOptions }"
		>
			<Button
				v-if="canMoveNext"
				icon
				@click="moveNext"
				class="arrow arrow--right"
				:class="cOptions.arrowsClass"
				:style="{ right: `${arrowsPosition}px` }"
			>
				<v-icon class="arrow-icon">mdi-chevron-right</v-icon>
			</Button>
		</slot>
		<slot name="free-slot" v-bind="{ currentIndex, moveTo }"> </slot>
	</div>
</template>

<style scoped>
@import '~@egjs/vue-flicking/dist/flicking.css';

.carousel-parent {
	position: relative;
	min-width: 0px;
	width: 100%;
}
.carousel-parent.uninit,
.carousel-parent.uninit * {
	opacity: 0;
	pointer-events: none;
}

.arrow {
	position: absolute;
	width: 55px;
	height: 55px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;
}
.arrow-icon {
	font-size: 70px;
}
</style>
