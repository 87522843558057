const crawlers = [
	'googlebot',
	'Yahoo! Slurp',
	'bingbot',
	'yandex',
	'baiduspider',
	'facebookexternalhit',
	'twitterbot',
	'rogerbot',
	'linkedinbot',
	'embedly',
	'quora link preview',
	'showyoubot',
	'outbrain',
	'pinterest/0.',
	'developers.google.com/+/web/snippet',
	'slackbot',
	'vkShare',
	'W3C_Validator',
	'redditbot',
	'Applebot',
	'WhatsApp',
	'flipboard',
	'tumblr',
	'bitlybot',
	'SkypeUriPreview',
	'nuzzel',
	'Discordbot',
	'Google Page Speed',
	'Qwantify',
	'pinterestbot',
	'Bitrix link preview',
	'XING-contenttabreceiver',
	'Chrome-Lighthouse',
	'TelegramBot',
	'Storebot', //Storebot-Google
]

const ua = navigator.userAgent.toLowerCase()

const isPrerenderAgent = ua == 'drubbit/prerender'
const isCrawlerAgent = crawlers.some((c) => ua.includes(c.toLowerCase()))
const isBotAgent = isPrerenderAgent || isCrawlerAgent

export { isBotAgent, isPrerenderAgent, isCrawlerAgent }
