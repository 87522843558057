<script>
export default {
	name: 'CardLayout',
	props: {
		title: String,
		showIcon: {
			type: Boolean,
			default: true,
		},
		clickable: Boolean,
		linkText: String,
	},
	methods: {
		linkClick() {
			this.$emit('linkClick')
		},
		cardClick() {
			if (!this.clickable) return
			this.$refs.card.$el.blur()
			this.$emit('cardClick')
		},
	},
}
</script>

<template>
	<v-card
		v-on="clickable ? { click: cardClick } : {}"
		class="mb-4 pa-5 pa-md-6"
		:class="{ 'pr-1': clickable }"
		ref="card"
		style="border-left: 4px solid currentColor"
	>
		<div v-if="title || $slots.title" class="pb-5 d-flex align-center">
			<slot name="title">
				<div class="font-2 font-weight-bold">
					{{ title }}
				</div>
				<v-spacer />
				<Button v-if="linkText" @click.prevent.stop="linkClick" color="link" text small>
					{{ linkText }}
				</Button>
			</slot>
		</div>
		<v-divider v-if="title || $slots.title" class="mb-4" />
		<v-card-actions :class="{ 'd-block': !clickable }" class="flex-nowrap pa-0">
			<div>
				<slot></slot>
			</div>
			<v-spacer v-if="clickable" />
			<v-icon v-if="clickable" color="success" right x-large> mdi-chevron-right </v-icon>
		</v-card-actions>
	</v-card>
</template>
