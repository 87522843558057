<script>
export default {
	lang: 'home',
	data() {
		return {
			email: '',
			isLoading: false,
			successMsg: false,
			formMsg: null,
			isSuccess: false,
			validation: {},
		}
	},
	methods: {
		saveEmail() {
			if (this.isLoading) {
				return
			}
			this.$shopApi.post({
				url: '/tienda/savenewsletteremail',
				data: {
					email: this.email,
				},
				loading: (v) => (this.isLoading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: () => {
					this.successMsg = this.$lang('Gracias por registrar tu correo en nuestro newsletter.')
					this.isSuccess = true
				},
			})
		},
	},
}
</script>

<template>
	<div>
		<form @submit.prevent>
			<Validator :validation="validation">
				<TextField
					v-if="!isSuccess"
					v-model="email"
					:label="$lang('Ingresá tu email')"
					@keydown.enter="saveEmail"
					outlined
					rounded
					solo
					flat
				>
					<template v-slot:append>
						<slot>
							<v-icon @click="saveEmail" v-if="!isLoading"> mdi-telegram </v-icon>
							<v-progress-circular
								v-if="isLoading"
								indeterminate
								color="primary"
							></v-progress-circular>
						</slot>
					</template>
				</TextField>
			</Validator>
		</form>
		<div v-if="successMsg" class="font-weight-bold">
			{{ successMsg }}
		</div>
	</div>
</template>

<style scoped>
.v-text-field {
	font-family: 'Playfair Display';
}
</style>
