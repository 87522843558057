<script>
export default {
	props: {
		image: String,
		imgMobile: String,
		inverse: Boolean,
		ctaTxt: String,
		ctaLink: String,
		text: String,
		products: Array,
	},
	computed: {
		productsColOrder() {
			return this.$vuetify.breakpoint.smAndDown || !this.inverse ? 3 : 1
		},
	},
}
</script>

<template>
	<Container :fluid="{ xs: true, md: false }">
		<v-row>
			<v-col :order="2" cols="12" md="6">
				<ImageCard
					:image="image"
					:img-mobile="imgMobile"
					:text="text"
					:cta-txt="ctaTxt"
					:cta-link="ctaLink"
				/>
			</v-col>
			<v-col :order="productsColOrder" cols="12" md="6">
				<v-row no-gutters class="px-2 px-sm-6 px-md-3">
					<v-col cols="6" v-for="product of products" :key="product.id" class="pa-2 pa-sm-6 pa-md-3">
						<ProductCard :product="product" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>
