<script>
export default {
	name: 'ProductPrevPrice',
	props: {
		amount: Number,
		currency: String,
		customClass: {
			type: String,
			default: 'grey--text',
		},
	},
}
</script>

<template>
	<del :class="customClass">
		<PriceText :amount="amount" :currency="currency" />
	</del>
</template>
